<template>
  <div class="evo-markdown">
    <mavon-editor
      id="md-preview"
      :value="value"
      :previewBackground="'#fafafa'"
      :toolbarsFlag="false"
      :subfield="false"
      :defaultOpen="'preview'"
      :editable="false"
      :scrollStyle="false"
      :boxShadow="false"
      :xssOptions="false"
      ref="md"
    />
  </div>
</template>
<script>
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css";
export default {
  name: "EvoMarkdown",
  components: {
    mavonEditor,
  },
  props: {
    content: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    }
  },
  computed: {
    value() {
      // 替换模板标签
      return this.content.replace(/<%= BASE_URL %>/g, this.publicPath)
    }
  }
};
</script>
<style lang="scss" scoped>
.evo-markdown {
  ::v-deep() {
    #md-preview {
      .v-show-content {
        padding: 0 !important;
      }

      &.markdown-body {
        z-index: 0;
        border: none !important;
      }
    }
  }
}
</style>
